export const constantes = {

    STORAGE_UNIAVAN_USER_ROLES: 'UNIAVAN_USER_ROLES',
    STORAGE_UNIAVAN_TIPO_USUARIO: 'UNIAVAN_TIPO_USUARIO',
    STORAGE_UNIAVAN_NOME_USUARIO: 'UNIAVAN_NOME_USUARIO',
    STORAGE_UNIAVAN_ID_INSTITUICAO: 'STORAGE_UNIAVAN_ID_INSTITUICAO',
    STORAGE_UNIAVAN_NOME_INSTITUICAO: 'STORAGE_UNIAVAN_NOME_INSTITUICAO',
    STORAGE_TEXTO_INTRODUTORIO: 'STORAGE_TEXTO_INTRODUTORIO',

    ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    ROLE_CLIENTE: 'ROLE_CLIENTE_ADMIN',
    ROLE_CLIENTE_ADMIN: 'ROLE_CLIENTE_ADMIN',
    ROLE_PROFESSOR: 'ROLE_PROFESSOR',
    ROLE_ALUNO: 'ROLE_ALUNO',

    ROLE_INSTITUICAO: 'ROLE_INSTITUICAO',
    ROLE_INSTITUICAO_ADMIN: 'ROLE_INSTITUICAO_ADMIN',

    USER_TIPO_RESPONSAVEL_CLIENTE: 'RESPONSAVEL_CLIENTE',

    STATUS_RASCUNHO: 'Rascunho',
    STATUS_EM_REVISAO: 'EmRevisao',
    STATUS_REPROVADO: 'Reprovado',
    STATUS_APROVADO: 'Aprovado',
    STATUS_DISPONIBILIZADO : 'Disponibilizado',
    STATUS_EM_ANDAMENTO: 'EmAndamento',
    STATUS_CONCLUIDO: 'Concluido',
    STATUS_EM_CORRECAO: 'EmCorrecao',
    STATUS_CORRIGIDO: 'Corrigido',
    STATUS_LIBERADO: 'Liberado',
    STATUS_AGENDADO: 'Agendado',
    EMPRESA: process.env.VUE_APP_EMPRESA,
}