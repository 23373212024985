import {constantes} from "@/constants";

export default {
    login: { method: 'post', url: 'login_check' },
    loadUserInfo: { method: 'get', url: 'users/info' },
    logout: () => {
        localStorage.removeItem(constantes.STORAGE_UNIAVAN_USER_ROLES);
        localStorage.removeItem(constantes.STORAGE_UNIAVAN_TIPO_USUARIO);
        localStorage.removeItem(constantes.STORAGE_UNIAVAN_NOME_USUARIO);
        localStorage.removeItem(constantes.STORAGE_TEXTO_INTRODUTORIO);
        localStorage.removeItem('token');        
    }
}